<template>
  <div>
    <div class="return-container">
      <h2 class="return-title">❌ Αποτυχία Κράτησης Επιστροφής</h2>

      <div>
        <section class="return-state">
          <div style="font-size:24px; margin-bottom: 10px">
            <h3>
              Επιστροφή:
              <strong style="font-weight:700">{{ barcodeId }}</strong>
            </h3>
            <br />
            <h3 style="margin-top: 10px;" v-if="reason === 'unknown'">
              Κάτι πήγε λάθος.
            </h3>
            <br />
            <h3 style="margin-top: 10px;">
              Το αίτημα επιστροφής απέτυχε, παρακαλώ δοκιμάστε αργότερα.
            </h3>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnOrderFail',
  components: {},
  props: {
    barcodeId: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.return-container {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  color: black;
  margin-top: 20px;
  line-height: 20px;
  flex: 1;
  display: flex;
}

.return-title {
  font-family: 'Proxima Nova';
  font-size: 2rem;
  font-weight: 500;
  line-height: 100%;
  color: black;
}

.return-header,
.return-state {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(
    255,
    0,
    0,
    0.6
  ); /* Add a semi-transparent background for better readability */
  border: 1px solid black;
  padding: 20px; /* Adjust padding as needed */
  border-radius: 10px; /* Optional: add border-radius for rounded corners */
  color: white;
}
@media (max-width: 768px) {
  .return-container {
    padding-top: 0px;
    max-width: 100%;
  }

  .return-header,
  .return-state {
    padding: 10px;
  }
}
</style>
