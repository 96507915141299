<template>
  <div class="bar">
    <button
      v-for="(availableSlotSize, index) in availableSlotSizes"
      :key="index"
      :class="[
        'bar-item',
        'button',
        { orange: selectedButton && selectedButton.size === availableSlotSize.size },
      ]"
      @click="selectButton(availableSlotSize)"
    >
      {{ availableSlotSize.nameGreek }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonSelector',
  props: {
    availableSlotSizes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedButton: null,
    };
  },
  computed: {
    lastItem() {
      return this.availableSlotSizes[this.availableSlotSizes.length - 1];
    },
  },
  watch: {
    availableSlotSizes: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.selectedButton = this.lastItem;
          this.$emit('buttonSelected', this.selectedButton);
        }
      },
    },
  },
  methods: {
    selectButton(selectedSlotSize) {
      this.selectedButton = selectedSlotSize;
      this.$emit('buttonSelected', selectedSlotSize);
    },
    resetSelection() {
      this.selectedButton = this.lastItem;
      this.$emit('buttonSelected', this.selectedButton);
    },
  },
};
</script>

<style scoped>
.bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}
.bar-item {
  cursor: pointer;
  text-align: center; /* Center the text */
  padding: 10px; /* Add some padding */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Adjust width for mobile view if needed */
@media (max-width: 800px) {
  .bar {
    justify-content: center;
  }
}

.orange {
  background-color: #ffa500 !important; /* Highlight selected button */
}

.button {
  background-color: white;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 140px;
  align-items: center;
  border: 2px solid #111111;
  border-radius: 4px;
  box-sizing: border-box;
  color: #111111;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 16px;
  height: 48px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: 700;
  margin: 8px 8px;
}

.button:hover {
  background-color: #ffcc79; /* Slightly lighter color on hover */
}

.button:after {
  background-color: #e07f00;
  border-radius: 4px;
  content: '';
  display: block;
  height: 44px;
  left: -2px;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.button:hover:after {
  transform: translate(0, 0);
}

.button:active {
  background-color: #ffdeda;
  outline: 0;
  font-weight: 400;
}

.button:hover {
  outline: 0;
}

@media (min-width: 800px) {
  .button {
    padding: 0 40px;
    width: 140px;
  }
  .button:after {
    width: 140px;
  }
}
</style>
