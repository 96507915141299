<template>
  <svg
    width="12px"
    height="3px"
    :viewBox="viewBox"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Front"
        transform="translate(-96.000000, -258.999992)"
        fill="#FFFFFF"
        fill-rule="nonzero"
      >
        <g
          id="g10"
          transform="translate(102.047331, 161.763996) scale(-1, 1) rotate(-180.000000) translate(-102.047331, -161.763996) translate(0.000000, 0.000000)"
        >
          <g id="g16" transform="translate(26.212399, 62.270665)">
            <g id="g54" transform="translate(70.391732, -0.000000)">
              <path
                d="M10.5719997,0.352666658 L0.314666659,0.352666658 C0.14133333,0.352666658 0,0.492666654 0,0.665999983 C0,0.839333312 0.14133333,0.980666642 0.314666659,0.980666642 L10.5719997,0.980666642 C10.7453331,0.980666642 10.8853331,0.839333312 10.8853331,0.665999983 C10.8853331,0.492666654 10.7453331,0.352666658 10.5719997,0.352666658"
                id="path56"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Dash",
  props: {
    viewBox: {
      default: "0 0 12 3",
    },
  },
};
</script>
