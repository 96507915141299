import { render, staticRenderFns } from "./ReturnOrderSuccess.vue?vue&type=template&id=2fba8dc3&scoped=true&"
import script from "./ReturnOrderSuccess.vue?vue&type=script&lang=js&"
export * from "./ReturnOrderSuccess.vue?vue&type=script&lang=js&"
import style0 from "./ReturnOrderSuccess.vue?vue&type=style&index=0&id=2fba8dc3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fba8dc3",
  null
  
)

export default component.exports