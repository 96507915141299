<template>
  <div>
    <Navbar/>

    <section>
      <h2>ΕΝΗΜΕΡΩΣΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</h2>
      <div class="privacy-container">
        <div class="bussiness-specs">
          <h5>ΕΙΣΑΓΩΓΗ</h5>
          <p class="p-align mt-1">
            Η εταιρία μας, με την επωνυμία «SKR STAFFING SERVICES ΜΟΝΟΠΡΟΣΩΠΗ ΙΔΙΩΤΙΚΗ ΚΕΦΑΛΑΙΟΥΧΙΚΗ ΕΤΑΙΡΙΑ» με έδρα 
            στην οδό Πειραιώς 167, Αθήνα 118 53 (εφεξής «SKR Staffing»), είναι κατά την ισχύουσα νομοθεσία υπεύθυνη 
            επεξεργασίας των προσωπικών σου δεδομένων στο πλαίσιο εκδήλωσης του ενδιαφέροντός σου να εργαστείς μαζί 
            μας (εφεξής η «Υποψηφιότητα»). Παράλληλα και κατά περίπτωση από κοινού με την SKR Staffing, ως υπεύθυνη 
            επεξεργασίας των προσωπικών σου δεδομένων στο πλαίσιο της Υποψηφιότητας, θεωρείται και η συνδεδεμένη με 
            την SKR Staffing εταιρία με την επωνυμία «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ ΜΟΝΟΠΡΟΣΩΠΗ ΙΔΙΩΤΙΚΗ ΚΕΦΑΛΑΙΟΥΧΙΚΗ 
            ΕΤΑΙΡΕΙΑ» και έδρα στην οδό Αγίας Άννης 7, Αθήνα 118 55 (εφεξής η «SLM») με έδρα επίσης στην οδό Πειραιώς 
            167, Αθήνα 118 53 (εφεξής από κοινού οι «Εταιρίες»).
          </p>
          <p class="p-align mt-1">
            Έχουμε ως στρατηγικό στόχο τη διασφάλιση της προστασίας των προσωπικών σου δεδομένων και για το λόγο αυτό 
            σου παρέχουμε την ακόλουθη ενημέρωση, αναφορικά με το είδος των προσωπικών δεδομένων που συλλέγουμε για 
            εσένα, τον τρόπο και τους σκοπούς για τους οποίους τα συλλέγουμε, τους τρίτους με τους οποίους μοιραζόμαστε 
            τα δεδομένα αυτά, καθώς και τα δικαιώματα που διαθέτεις, σε συμμόρφωση προς το Ν. 2472/1997, το Ν. 4624/2019, 
            καθώς και το Γενικό Κανονισμό Προστασίας Δεδομένων της ΕΕ 2016/679 (ΓΚΠΔ).
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ ΠΟΥ ΣΥΛΛΕΓΟΥΜΕ</h5>
          <p class="p-align mt-1">
            Στο πλαίσιο της Υποψηφιότητας, οι Εταιρίες συλλέγουν και επεξεργάζονται περαιτέρω –κατά περίπτωση- τα ακόλουθα προσωπικά σου δεδομένα:
            <ul>
              <li>Στοιχεία ταυτοποίησης, όπως: ονοματεπώνυμο, ηλικία, εθνικότητα</li>
              <li>Στοιχεία επικοινωνίας, όπως: ταχυδρομική διεύθυνση, διεύθυνση ηλεκτρονικής αλληλογραφίας, αριθμός κινητού ή/και σταθερού τηλεφώνου</li>
              <li>Στοιχεία που αφορούν στην εκπαίδευση, την επαγγελματική σου εμπειρία και τυχόν πιστοποιήσεις ή άλλα επαγγελματικής φύσης προσόντα που διαθέτεις</li>
              <li>Πληροφορίες σχετικά με την εκπλήρωση ή μη τυχόν υποχρεωτικής στρατιωτικής θητείας</li>
              <li>Πληροφορίες σχετικά με γενικότερα ενδιαφέροντά σου</li>
              <li>Πληροφορίες σχετικά με την άδεια οδήγησης και την άδεια παραμονής που διαθέτεις</li>
              <li>Στοιχεία αξιολόγησης της καταλληλόλητάς σου για εργασία μαζί μας σε σχέση με το αντικείμενο και τη θέση στα οποίο αφορά η Υποψηφιότητά σου</li>
              <li>Τεχνικές πληροφορίες που δημιουργούνται στο πλαίσιο της online υποβολής της αίτησής σου, όπως διεύθυνση IP, Myjobnow ID και στοιχεία cookies</li>
              <li>Στοιχεία σύνδεσης μέσω Facebook ή Google</li>
              <li>Πληροφορίες σχετικά με την αξιολόγησή σου, όπως τα αποτελέσματα από το κουίζ ή/και σχόλια από τη συνέντευξη μαζί σου</li>
              <li>Πληροφορίες που αφορούν σε τυχόν λόγους απόρριψης της Υποψηφιότητάς σου</li>
              <li>Υπογραφή</li>
            </ul>
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>ΓΙΑΤΙ ΤΑ ΣΥΛΛΕΓΟΥΜΕ</h5>
          <p class="p-align mt-1">
            Ενόψει της συμβατικής σχέσης εργασίας που ενδέχεται να συνάψουμε μαζί σου, νομιμοποιούμαστε να συλλέγουμε από εσένα 
            και να επεξεργαζόμαστε τα ανωτέρω προσωπικά δεδομένα που μας είναι εντελώς απαραίτητα για το σκοπό καταγραφής και 
            αξιολόγησης της Υποψηφιότητάς σου. Είναι προτεραιότητά των Εταιρειών μας να επιλέγουμε προσεκτικά τους ανθρώπους 
            που πρόκειται να εργαστούν μαζί μας, με βάση αξιολογικά κριτήρια και να βεβαιώνουμε ότι διαθέτουν όλα τα κατάλληλα 
            εκπαιδευτικά και επαγγελματικά προσόντα.
          </p>
          <p class="p-align mt-1">
            Για να αξιολογήσουμε την Υποψηφιότητά σου, επεξεργαζόμαστε τα προσωπικά σου δεδομένα, όπως αυτά περιέχονται σε 
            ηλεκτρονικές αιτήσεις εκδήλωσης ενδιαφέροντος, σε βιογραφικά σημειώματα ή όπως αυτά προκύπτουν από τυχόν κατ’ ιδίαν 
            συνέντευξη μαζί σου ή κάποιο τεχνικό τεστ που σχετίζεται με το αντικείμενο εργασίας.
          </p>
          <p class="p-align mt-1">
            Στην περίπτωση που χρειαστεί να επεξεργαστούμε τα προσωπικά σου δεδομένα για κάποιον σκοπό πέραν των ανωτέρω, θα 
            σε ενημερώσουμε κατάλληλα και εκ των προτέρων και θα ζητήσουμε τη συγκατάθεσή σου, εφόσον αυτό απαιτείται.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>ΜΕ ΠΟΙΟΥΣ ΤΑ ΜΟΙΡΑΖΟΜΑΣΤΕ</h5>
          <p class="p-align mt-1">
            Τα προσωπικά σου δεδομένα, συλλέγονται και γίνονται αντικείμενο περαιτέρω επεξεργασίας από την υπεύθυνη, 
            εξουσιοδοτημένη προς αυτό ομάδα της SLM και της SKR Staffing.
          </p>
          <p class="p-align mt-1">
            Στο πλαίσιο της καλύτερης διαχείρισης της Σχέσης αλλά και των λειτουργικών και οργανωτικών αναγκών της SLM και 
            της SKR Staffing, και πάντοτε σε συμφωνία με την ισχύουσα νομοθεσία για την προστασία προσωπικών δεδομένων, 
            αποδέκτες των προσωπικών σου δεδομένων μπορεί να είναι:
            <ul>
              <li>Πάροχος της πλατφόρμας για την αναζήτηση νέων υπαλλήλων, όπως η Myjobnow. </li>
              <li>Εταιρίες μέσων κοινωνικής δικτύωσης εφόσον επιλέξεις να εγγραφείς μέσω του λογαριασμού που διατηρείς σε 
                αυτές, όπως η Google και η Facebook, αλλά και πάροχοι λογισμικού για τον προγραμματισμό της συνέντευξής σου 
                μαζί μας, όπως η Calendly. Στο πλαίσιο λειτουργίας των εταιριών αυτών, τα δεδομένα σου ενδέχεται να διαβιβαστούν 
                και εκτός Ευρωπαϊκού Χώρου και ιδίως στις ΗΠΑ, ωστόσο εφαρμόζονται σχετικά, αυξημένα μέτρα προστασίας, όπως 
                ειδικές τυποποιημένες συμβατικές ρήτρες και τεχνική παραμετροποίηση.
              </li>
            </ul>
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>ΠΟΣΟ ΚΑΙΡΟ ΤΑ ΔΙΑΤΗΡΟΥΜΕ</h5>
          <p class="p-align mt-1">
            Τα στοιχεία της δήλωσης ενδιαφέροντός σου που συλλέγονται για το σκοπό αξιολόγησης της Υποψηφιότητάς σου, καθώς 
            και τα σχετικά αποτελέσματα της αξιολόγησης, τηρούνται από εμάς για όσο χρόνο απαιτείται για την εκπλήρωση του 
            σκοπού αυτού και σε κάθε περίπτωση, για δύο (2) έτη από τη συλλογή τους, εκτός και εάν, στο μεταξύ, μας ενημερώσεις 
            ότι επιθυμείς τη διαγραφή τους.
          </p>
          <p class="p-align mt-1">
            Σου υπενθυμίζουμε ότι, στην περίπτωση που λάβαμε τα στοιχεία σου μέσω κάποιας πλατφόρμας εύρεσης εργασίας, ενδέχεται 
            να εφαρμόζεται παράλληλα και η δική της τυχόν Πολιτική Απορρήτου που να προβλέπει μεταξύ άλλων, μεγαλύτερο χρονικό 
            διάστημα διατήρησης των στοιχείων σου στα δικά τους συστήματα.
          </p>
          <p class="p-align mt-1">
            Στην περίπτωση που επιθυμούμε να τα διατηρήσουμε για μεγαλύτερο χρονικό διάστημα, θα ζητήσουμε τη συγκατάθεσή σου.
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>ΤΑ ΔΙΚΑΙΩΜΑΤΑ ΣΟΥ</h5>
          <p class="p-align mt-1">
            Εφόσον συντρέχουν οι προϋποθέσεις της νομοθεσίας και στο πλαίσιο της Υποψηφιότητας, έχεις και μπορείς να ασκήσεις τα ακόλουθα δικαιώματα:
            <ul>
              <li><span class="strong">το δικαίωμα πρόσβασης</span>, το δικαίωμα δηλαδή αφενός να ενημερωθείς για το κατά πόσον τα προσωπικά σου δεδομένα υφίστανται 
                επεξεργασία και αφετέρου να λάβεις περαιτέρω πληροφορίες σχετικά με την πραγματοποιούμενη επεξεργασία,
              </li>
              <li><span class="strong">το δικαίωμα να ζητήσεις τη διόρθωση</span> τυχόν ανακριβών προσωπικών σου δεδομένων ή τη συμπλήρωση αυτών,</li>
              <li>καθώς και εφόσον συντρέχουν οι προϋποθέσεις της νομοθεσίας:</li>
              <li><span class="strong">το δικαίωμα να εναντιωθείς</span> στην επεξεργασία των δεδομένων σου που βασίζεται στο έννομο συμφέρον των Εταιριών,</li>
              <li><span class="storng">το δικαίωμα στη φορητότητα</span> των δεδομένων σου προς άλλον υπεύθυνο επεξεργασίας ή προς εσένα, σε κατάλληλο μορφότυπο,</li>
              <li><span class="strong">το δικαίωμα διαγραφής,</span></li>
              <li><span class="strong">το δικαίωμα περιορισμού</span> της επεξεργασίας,</li>
              <li><span class="strong">το δικαίωμα να αποσύρεις τυχόν ειδική συγκατάθεσή</span> σου.</li>
            </ul>
          </p>
          <p class="p-align mt-1">
            Σε περίπτωση άσκησης των δικαιωμάτων διόρθωσης, διαγραφής και περιορισμού των δεδομένων σου, τα αιτήματα αυτά θα διαβιβάζονται και 
            στους τρίτους αποδέκτες στους οποίους τα δεδομένα αυτά τυχόν γνωστοποιήθηκαν από εμάς.
          </p>
          <p class="p-align mt-1">
            Μπορείς να ασκήσεις οποιοδήποτε από τα παραπάνω δικαιώματα, υποβάλλοντας έγγραφο αίτημα στον Υπεύθυνο Προστασίας Δεδομένων 
            (Data Protection Officer) της SLM, <span class="strong">ως μόνο αρμόδιο κανάλι επικοινωνίας και για τις δύο</span> Εταιρίες. 
            Μπορείς να αναμένεις απάντηση σε τέτοιο αίτημα εντός ενός (1) μήνα μετά τη λήψη του και σε κάθε περίπτωση, εντός δύο (2) επιπλέον 
            μηνών, εφόσον αυτό επιβάλλεται από την πολυπλοκότητα του αιτήματος ή το γενικότερο αριθμό των ληφθέντων αιτημάτων. 
          </p>
        </div>
        <div class="bussiness-specs">
          <h5>ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</h5>
          <p class="p-align mt-1">
            Σε περίπτωση που θεωρείτε ότι δεν ικανοποιήσαμε επαρκώς το αίτημά σας και θίγεται κατά οποιονδήποτε τρόπο η προστασία των προσωπικών 
            σας δεδομένων, μπορείς να υποβάλεις καταγγελία μέσω <a href="https://www.dpa.gr/el/syndesi/prosvasi">ειδικής διαδικτυακής πύλης</a> 
            στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (Αθήνα, Λεωφόρος Κηφισίας 1-3, Τ.Κ. 115 23 | τηλ: +30 210 6475600). Αναλυτικές 
            οδηγίες υποβολής καταγγελίας παρέχονται στην <a href="https://www.dpa.gr/el/polites/katagelia_stin_arxi">Ιστοσελίδα</a> της Αρχής.
          </p>
        </div>

      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '../pages-sections/Footer.vue'
import Navbar from '../pages-sections/Navbar.vue'
export default {
  components: {
    Navbar, 
    Footer 
  },

}
</script>

<style lang="scss" scoped>
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: unset;
    border-radius: unset;
    
    h2 {
      font-weight: bold;
      line-height: 40px;
    }
  }
  .privacy-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 600px;
    width: 100%;
    max-width: 780px;

    .p-align {
      text-align: justify;
    }
  }

  .bussiness-specs {
    margin-top: 1rem;

    h5 {
      font-weight: bold;
      font-size: 1.2em;
    }
    p {
      margin: 0.5rem 0;
    }
    ul {
      margin-left: 2rem;
      li {
        list-style-type: disc;
        margin-top: 12px;
        list-style-position: outside;
        
        &::before {
          content: "";
          display: inline-block;
        }
      }
    }
    .strong {
      font-weight: bold;
    }
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .ml-1 {
    margin-left: 1rem !important;
  }
</style>