<template>
  <div class="flex-wrapper-app">
    <Navbar />
    <div class="return-container-main">
      <div class="container" v-if="isOrderCanReturn">
        <div v-if="isSuccess === true">
          <ReturnOrderSuccess :barcodeId="this.barcodeId" :reason="other" />
        </div>
        <div v-else-if="isSuccess === false">
          <ReturnOrderFail :barcodeId="this.barcodeId" :reason="other" />
        </div>
        <div v-else>
          <h2 class="return-title">
            {{
              this.dropoffType === '0'
                ? 'Ανακυκλώστε τις Nespresso κάψουλες σας'
                : 'Επιστροφή στο Κατάστημα'
            }}
          </h2>
          <div v-if="!authorized">
            <div class="initial-order-info">
              <h3>
                Αρχική Παραγγελία:
                <strong class="font-weight-bold">{{ barcodeId }}</strong>
              </h3>
              <h3 class="margin-top-10">
                <h4 class="margin-3-0">
                  Εισάγετε το κινητό τηλέφωνο που χρησιμοποιήσατε στην αρχική παραγγελία.
                </h4>
                <PhoneNumberInput
                  @update-phone-number="handlePhoneNumberUpdate"
                  @phone-number-enter="handlePhoneNumberEnter"
                  @clear-input-done="resetClearInputFlag"
                  :clear-input="clearInput"
                />
                <p v-if="errorMessage" class="error-message">
                  {{ errorMessage }}
                </p>
                <h4 class="info-container">
                  <div class="info-icon-margin">
                    <img
                      src="../../../assets/images/info-icon.svg"
                      alt="search-icon"
                      width="24"
                      height="24"
                    />
                  </div>
                  <span class="font-weight-bold"
                    >Η παραγγελία σας είναι συνδεδεμένη σε αυτό το νούμερο. Πρέπει να έχετε πρόσβαση
                    σε αυτή τη συσκευή για να ολοκληρώσετε την επιστροφή.</span
                  >
                </h4>
                <button
                  v-if="phoneNumber && !isLoading"
                  class="margin-top-5"
                  @click="handleCheckNumber"
                >
                  Έλεγχος
                </button>
                <div :class="['loader-overlay', { 'loader-overlay-hide': !isLoading }]">
                  <Loader />
                </div>
              </h3>
            </div>
          </div>

          <div
            v-else-if="authorized"
            :class="['return-main-container', { 'return-main-container-loading': isLoading }]"
          >
            <div :class="['loader-overlay', { 'loader-overlay-hide': !isLoading }]">
              <Loader />
            </div>
            <div class="initial-order-info">
              <div class="return-map-header-container">
                <div>
                  <h3>
                    Αρχική Παραγγελία:
                    <strong class="font-weight-bold">{{ barcodeId }}</strong>
                  </h3>
                  <h3 class="margin-top-5" v-if="phoneNumber !== '****'">
                    Κινητό Τηλέφωνο:
                    <strong class="font-weight-bold">{{ phoneNumber }}</strong>
                  </h3>
                </div>
                <div class="flex-end">
                  <h4 class="information-container">
                    <div class="info-icon-margin">
                      <img
                        src="../../../assets/images/info-icon.svg"
                        alt="search-icon"
                        width="24"
                        height="24"
                      />
                    </div>
                    <span class="font-weight-bold"
                      >Θα λάβετε οδηγίες στο κινητό τηλέφωνο που έχετε δηλώσει στην αρχική
                      παραγγελία. Θα χρειαστεί να έχετε πρόσβαση σε αυτή την συσκευή ώστε να
                      {{
                        this.dropoffType === '0'
                          ? ' ανακυκλώσετε τις κάψουλες σας.'
                          : ' ολοκληρώσετε την επιστροφή.'
                      }}</span
                    >
                  </h4>
                </div>
              </div>

              <div class="return-grid-table">
                <h3>
                  Επιλέξτε το Skroutz Point από το χάρτη για να
                  {{
                    this.dropoffType === '0'
                      ? ' ανακυκλώσετε τις κάψουλες σας.'
                      : ' τοποθετήσετε το δέμα σας.'
                  }}
                </h3>

                <button @click="showMap = true && isMobile" class="show-map-button">
                  Δείξε τα Skroutz Points
                </button>

                <div v-show="showMap || !isMobile" class="popup-place-slm-map-container">
                  <div class="popup-place-slm-map" style="text-align:right">
                    <span class="closeMapBtn" @click="closeMap" v-if="isMobile">ⓧ</span>
                    <div id="skroutzLockerMap" class="return-map"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[
                'margin-top-10',
                {
                  invisible: !(
                    returnExternalId &&
                    returnLockerFriendlyName &&
                    returnLockerAddress &&
                    !isLoading
                  ),
                },
              ]"
            >
              <p class="return-selected-locker-message">
                Έχετε επιλέξει το Skroutz Point:
                <strong class="return-selected-highlight">{{ returnLockerFriendlyName }}</strong>
                στην διεύθυνση:
                <strong class="return-selected-highlight">{{ returnLockerAddress }}</strong>
              </p>
              <button class="return-confirm-button margin-top-10" @click="handleCreateReturnOrder">
                Επιβεβαίωση
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div :class="['loader-overlay', { 'loader-overlay-hide': !isLoading }]">
          <Loader />
        </div>
        <h4 class="return-title">{{ errorMessage }}</h4>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from 'axios';

import Navbar from '../../pages-sections/Navbar.vue';
import Footer from '../../pages-sections/Footer.vue';
import PhoneNumberInput from '../../pages-sections/PhoneNumberInput.vue';
import ReturnOrderSuccess from '../../pages/ExternalReturn/ReturnOrderSuccess.vue';
import ReturnOrderFail from '../../pages/ExternalReturn/ReturnOrderFail.vue';
import Loader from '../../loader/Loader.vue';
export default {
  name: 'ReturnOrder',
  components: {
    Navbar,
    Footer,
    PhoneNumberInput,
    Loader,
    ReturnOrderSuccess,
    ReturnOrderFail,
  },
  data() {
    return {
      isOrderCanReturn: false,
      dropoffType: '0', //0: return, 1: recycle
      barcodeId: '',
      isLoading: false,
      authorized: false,
      isSuccess: null,
      returnExternalId: '',
      returnLockerFriendlyName: '',
      returnLockerAddress: '',
      phoneNumber: '',
      clearInput: false,
      errorMessage: '',
      showMap: false,
      isMobile: false,
    };
  },
  mounted() {
    this.barcodeId = this.$route.params?.barcodeId;
    this.dropoffType = this.$route.params?.dropoffType;

    if (
      this.$router.history?.current?.meta &&
      this.$router.history.current.meta.navigationSource === 'return-button'
    ) {
      this.isOrderCanReturn = true;
      this.handleCheckNumber();
    } else if (this.barcodeId.startsWith('ER-')) {
      this.handleHashedBarcode(this.barcodeId);
    } else {
      this.isOrderEligibleReturn(this.barcodeId);

      this.checkViewportWidth();
      window.addEventListener('resize', this.checkViewportWidth);
    }
  },
  methods: {
    checkViewportWidth() {
      this.isMobile = window.innerWidth < 800;
    },
    async isOrderEligibleReturn(barcode) {
      const isNumberAuthorized = await this.handleCheckNumber();

      if (!isNumberAuthorized) {
        try {
          this.isLoading = true;
          const response = await axios.get(
            `${process.env.VUE_APP_COURIER_API}/user/partner/returnEligible/${barcode}`,
          );
          if (response.status === 200) {
            this.isOrderCanReturn = true;
            this.errorMessage = '';
          } else {
            this.errorMessage = 'Αυτή η παραγγελία δεν προορίζεται για επιστροφή.';
          }
        } catch (error) {
          if (error.response.status === 403) {
            this.errorMessage = error?.response?.data?.description
              ? error.response.data.description
              : `Αυτή η παραγγελία δεν προορίζεται για επιστροφή`;

            this.isOrderCanReturn = false;
          } else {
            this.errorMessage = 'Αυτή η παραγγελία δεν προορίζεται για επιστροφή.';
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    async handleHashedBarcode(hashedBarcode) {
      try {
        this.isLoading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_COURIER_API}/user/partner/validateHash`,
          {
            voucherHashed: hashedBarcode,
          },
        );
        if (response.status === 200) {
          this.barcodeId = response.data.barcode;
          this.phoneNumber = response.data.phone;
          this.isOrderCanReturn = true;
          this.authorized = true;
          this.errorMessage = '';
          this.initMap();
        } else {
          this.errorMessage = 'Αυτή η παραγγελία δεν προορίζεται για επιστροφή.';
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.errorMessage = error?.response?.data?.description
            ? error.response.data.description
            : `Αυτή η παραγγελία δεν προορίζεται για επιστροφή`;
        } else {
          this.errorMessage = 'Αυτή η παραγγελία δεν προορίζεται για επιστροφή.';
        }
        this.isOrderCanReturn = false;
      } finally {
        this.isLoading = false;
      }
    },
    handlePhoneNumberUpdate(phoneNumber) {
      this.errorMessage = '';
      this.phoneNumber = phoneNumber;
    },
    handlePhoneNumberEnter(phoneNumber) {
      this.phoneNumber = phoneNumber;
      if (phoneNumber) {
        this.handleCheckNumber();
      }
    },
    clearPhoneNumber() {
      this.clearInput = true;
    },
    resetClearInputFlag() {
      this.clearInput = false;
    },
    async handleCheckNumber() {
      this.clearInput = true;
      const originalPhoneNumber = this.phoneNumber;
      try {
        this.isLoading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_COURIER_API}/user/partner/validateReturn`,
          {
            reference_tracking_id: this.barcodeId,
            original_phone_number: originalPhoneNumber,
          },
        );

        if (response.status === 200) {
          this.errorMessage = '';
          this.authorized = true;
          this.isOrderCanReturn = true;
          this.initMap();
          if (!originalPhoneNumber) {
            this.phoneNumber = '****';
          }
          return true;
        } else {
          if (originalPhoneNumber) {
            this.errorMessage = `Το νούμερο ${originalPhoneNumber} δεν αντιστοιχεί στην αρχική παραγγελία.`;
          }

          return false;
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          this.errorMessage = error?.response?.data?.description
            ? error.response.data.description
            : `Αυτή η παραγγελία δεν προορίζεται για επιστροφή`;

          this.isOrderCanReturn = false;
        }
        if (originalPhoneNumber) {
          this.errorMessage = `Το νούμερο ${originalPhoneNumber} δεν αντιστοιχεί στην αρχική παραγγελία.`;
        }
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    async handleCreateReturnOrder() {
      if (window.confirm(`Επιστροφή στο Skroutz Point: ${this.returnLockerFriendlyName}`)) {
        const originalPhoneNumber = this.phoneNumber;
        try {
          this.isLoading = true;
          const response = await axios.post(
            `${process.env.VUE_APP_COURIER_API}/user/partner/createReturnShippingOrder`,
            {
              reference_tracking_id: this.barcodeId,
              original_phone_number: originalPhoneNumber,
              skroutz_point_id: this.returnExternalId,
            },
          );
          if (response.status === 200) {
            this.isSuccess = true;
          } else {
            this.isSuccess = false;
          }
        } catch (error) {
          this.isSuccess = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
    initMap() {
      var _skroutzPointsMapWidgetOptions = {
        partnerId: 'skou',
        language: 'el',
        type: 'iframe',
        parentElement: '#skroutzLockerMap',
        viewType: 'return',
        afterSelect: selected => {
          if (selected?.id) {
            this.returnExternalId = selected.id;
            this.returnLockerFriendlyName = selected.name;
            this.returnLockerAddress = `${selected.street_name} ${selected.street_number}, ${selected.city} ${selected.zip} `;
          } else if (selected === null) {
            this.returnExternalId = '';
            this.returnLockerFriendlyName = '';
            this.returnLockerAddress = '';
          }
        },
      };
      window._skroutzPointsMapWidgetOptions = _skroutzPointsMapWidgetOptions;

      (function(d) {
        var e = d.createElement('script');
        e.src = `${process.env.VUE_APP_MAP}`;
        e.async = true;
        e.defer = true;
        d.getElementsByTagName('head')[0].appendChild(e);
      })(document);
    },
    closeMap() {
      this.showMap = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkViewportWidth);
  },
};
</script>

<style scoped lang="scss">
.return-title {
  line-height: 34px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 26px;
}

.return-description-text {
  display: inline-block;
  font-size: 22px;
  background-color: #8f6eff;
  color: #dbdbdb;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  line-height: 100%;
}

.return-header,
.return-state {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.return-main-container-loading {
  pointer-events: none;
  background: black;
  opacity: 30%;
}

.return-grid-table {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  margin-top: 15px;
}

.return-map {
  height: 50vh;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.return-title-col {
  font-weight: bold;
}

.return-selected-locker-message {
  background-color: #f68b24;
  font-size: 16px;
  padding: 6px;
  line-height: 150%;
  text-align: center;
  color: white;
}

.return-selected-highlight {
  font-weight: 700;
  color: white;
}

.return-confirm-button {
  min-width: 150px;
  padding: 8px;
  font-size: 30px;
  text-align: center;
  background-color: #279a00;
  &:hover {
    background-color: green;
  }
}

.return-confirm-button-main-text {
}
.return-confirm-button-sub-text {
  font-size: 12px;
  color: #000;
  width: 200px;
  margin-top: 1px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: wait;
}

.loader-overlay-hide {
  display: none;
}

.return-container {
  background-repeat: repeat no-repeat !important;
}

.return-container-main {
  flex-grow: 1;
  display: flex;
  background: url('../../../assets/images/graphic-bg-tracking.svg');
  background-repeat: no-repeat !important;
  background-position: center bottom;
}

h4 {
  width: 490px;
  font-size: 16px;
  font-weight: normal;
  color: rgb(54, 54, 54);
  line-height: 26px;
}

.information-container {
  display: flex;
  align-items: start;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.return-map-header-container {
  display: grid;
  grid-template-columns: auto auto;
}

@media screen and (max-width: 733px) {
  .return-container-main {
    padding: 56px 0 224px;
  }
}

@media screen and (min-width: 733px) and (max-width: 961px) {
  .return-container-main {
    padding: 136px 0 433px;

    .return-container {
      margin: 0 59px;
    }
  }
}
.show-map-button {
  display: none;
  margin: 20px 0;
}

@media screen and (max-width: 800px) {
  .show-map-button {
    align-items: center;
    border: 2px solid #111111;
    border-radius: 4px;
    box-sizing: border-box;
    color: #111111;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 16px;
    height: 60px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: 700;
    margin: 20px 8px;

    padding: 10px 20px;
    font-size: 20px;
    background-color: #ffffff;
    width: 200px;
  }

  .show-map-button:after {
    background-color: #e07f00;
    border-radius: 4px;
    content: '';
    display: block;
    height: 58px;
    left: -2px;
    width: 100%;
    position: absolute;
    top: -2px;
    transform: translate(8px, 8px);
    transition: transform 0.2s ease-out;
    z-index: -1;
  }

  .show-map-button:hover {
    background-color: #ffcc79;
  }

  .show-map-button:hover:after {
    transform: translate(0, 0);
  }

  .show-map-button:active {
    background-color: #ffdeda;
    outline: 0;
    font-weight: 400;
  }

  .popup-place-slm-map-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 19997;
  }

  .popup-place-slm-map {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: none;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 20px;
  }
  .closeMapBtn {
    font-size: 50px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 20000;
    color: #f3b645;
  }

  .return-map {
    height: 100vh;
  }
}

//mobile
@media screen and (max-width: 961px) {
  .return-container-main {
    background: url('../../../assets/images/graphic-bg-tracking.svg') bottom center;
    justify-content: bottom;
    background-position: center bottom;
    background-repeat: no-repeat !important;
    width: 100%;

    .return-container {
      width: 100%;
    }

    min-height: 50vh;
  }
  .return-map-header-container {
    grid-template-columns: auto;
  }

  .information-container {
    margin: 4px 0;
    width: 100%;
  }
  .return-map {
    height: 110vh;
  }

  h4 {
    width: 100%;
  }
}
//desktop
@media screen and (min-width: 961px) {
  .return-container {
    max-width: 1320px !important;
  }
  .return-container-main {
    padding: 146px 0 80px;
  }
}

//big screen desktop
@media screen and (min-width: 961px) and (max-width: 1500px) {
  .return-container {
    margin-left: 64px !important;
  }
}

.font-weight-bold {
  font-weight: 700;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-3-0 {
  margin: 3px 0;
}

.info-container {
  display: flex;
  align-items: start;
  margin: 6px 0;
}

.info-icon-margin {
  margin-right: 3px;
}

.margin-top-5 {
  margin-top: 5px;
}

.flex-end {
  display: flex;
  justify-content: end;
}

.margin-top-10 {
  margin-top: 10px;
}

.min-height-300 {
  min-height: 300px;
}

.initial-order-info {
  font-size: 20px;
}

.invisible {
  visibility: hidden !important;
}
</style>
