<template>
  <div>
    <div class="bg-driver">
      <Navbar />

      <div v-if="!barcodeId" class="card-container">
        <div class="flex-form">
          <h3>
            Πληκτρολόγησε τον <span class="strong">ΚΩΔΙΚΟ</span>
            <br />
            <span class="op-5"> που έχεις στη διάθεσή σου</span>
          </h3>
          <form @submit="validateTrackId">
            <p>
              <span class="search-bar-input-wrapper">
                <input
                  type="search"
                  placeholder="κωδικός"
                  v-model="searchBarcodeId"
                />
              </span>
              <button type="submit" value="submit">
                <img
                  src="../../assets/images/search-icon.svg"
                  alt="search-icon"
                  width="16"
                  height="16"
                />
              </button>
            </p>
          </form>
        </div>
      </div>

      <div v-else class="card-container">
        <Loader v-if="isLoading" />

        <!-- Employee do not exist -->
        <div v-else-if="!currentEmployee" class="warning-container">
          <Dash viewBox="0 0 13 3" style="transform: translateX(-30px);" />
          <div class="w-message">
            <img
              src="@/assets/images/warning_white_24dp.svg"
              alt="warning"
              width="80"
            />
            <h2>WARNING!</h2>
            <span>Έχετε καταχωρήσει σωστό barcode εργαζομένου?</span>
          </div>
          <div class="notify-holder">
            <NotifyLoader v-if="notifyLoader && !isRequestDone" />
            <div v-else-if="isRequestDone" class="request-msg">
              <img src="@/assets/images/check_circle_outline.svg" alt="" />
              <h5>Απεστάλη</h5>
            </div>
            <div v-else class="btn" @click="notifyEmployee">
              Ειδοποίησε μας
            </div>
          </div>
        </div>

        <!-- Employee has not an active contract or has not a shift -->
        <div v-else-if="!isHired || !hasShift" class="not-hired">
          <div class="employee-specs">
            <div class="img hire-img">
              <img
                v-if="employeeAvatarIsValid"
                src="https://s3-eu-west-1.amazonaws.com/static.myjobnow.com/photos/seeker_placeholder_profile_pic%403x.png"
                alt="avatar"
                class="avatar"
              />
              <img v-else :src="getImg()" alt="avatar" />
            </div>
            <div class="details">
              <h4>
                {{
                  currentEmployee.last_name + " " + currentEmployee.first_name
                }}
              </h4>
              <div>
                Πρόσληψη: <span>{{ isHired ? "Ναι" : "Όχι" }}</span>
              </div>
              <div>
                Βάρδια: <span>{{ hasShift ? "Ναι" : "Όχι" }}</span>
              </div>
            </div>
          </div>
          <div class="w-message">
            <img
              src="@/assets/images/warning_white_24dp.svg"
              alt="warning"
              width="80"
            />
            <span>
              Προσοχή,
              {{
                !isHired ? "η πρόσληψη δεν έχει γίνει!" : "δεν βρέθηκε βάρδια!"
              }}
            </span>
          </div>
          <div class="notify-holder">
            <NotifyLoader v-if="notifyLoader && !isRequestDone" />
            <div v-else-if="isRequestDone" class="request-msg">
              <img src="@/assets/images/check_circle_outline.svg" alt="" />
              <h5>Απεστάλη</h5>
            </div>
            <div v-else class="btn" @click="notifyEmployee">
              Ειδοποίησε μας
            </div>
          </div>
        </div>

        <div v-else class="personal-details">
          <Dash viewBox="0 0 14 3" style="transform: translateX(-26px);" />
          <div class="img">
            <img
              v-if="employeeAvatarIsValid"
              src="https://s3-eu-west-1.amazonaws.com/static.myjobnow.com/photos/seeker_placeholder_profile_pic%403x.png"
              alt="avatar"
              class="avatar"
            />
            <img v-else :src="getImg()" alt="avatar" />
          </div>
          <div class="driver-name">
            <h4>{{ currentEmployee.last_name }}</h4>
            <h4>{{ currentEmployee.first_name }}</h4>
          </div>
          <Dash />
          <span>Skroutz οδηγός</span>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";

import Navbar from "../pages-sections/Navbar.vue";
import Footer from "../pages-sections/Footer.vue";
import Dash from "../graphic/Dash.vue";
import NotifyLoader from "../loader/NotifyLoader.vue";
import Loader from "../loader/Loader.vue";

export default {
  name: "VerifiedEmployee",
  components: {
    Navbar,
    Footer,
    Dash,
    NotifyLoader,
    Loader,
  },
  data() {
    return {
      barcodeId: "",
      searchBarcodeId: "",
      currentEmployee: null,
      employeeAvatarIsValid: true,
      isHired: false,
      hasShift: false,
      notifyLoader: false,
      isRequestDone: false,
      isBarcodeCopied: false,
      isLoading: true,
    };
  },
  mounted() {
    this.barcodeId = this.$route.params?.barcodeId;

    if (this.barcodeId) {
      this.verificationRequest();
    }
  },
  methods: {
    validateTrackId() {
      this.$router.push({
        name: "verifiedEmployeeId",
        params: { barcodeId: this.searchBarcodeId },
      });
    },
    async verificationRequest() {
      const request = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tracking_page/verify_employee`,
        {
          params: {
            barcode: this.barcodeId,
          },
        }
      );

      if (request.data?.employee) {
        this.currentEmployee = request.data.employee;
        this.isHired = request.data.is_hired;
        this.hasShift = request.data.has_shift;
      }

      this.isLoading = false;
    },
    async notifyEmployee() {
      this.notifyLoader = true;

      await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tracking_page/verify_employee/report_failed`,
        {
          barcode: this.barcodeId,
        }
      );

      this.notifyLoader = false;
      this.isRequestDone = true;
    },
    getImg() {
      let src = "";

      try {
        src = require(this.currentEmployee.photo_url);
      } catch (e) {
        this.employeeAvatarIsValid = false;
        src = "";
      }

      return src;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/search_bar.scss";

$white: #fff;
$orange: #f68b24;
$orange8: rgb(246 139 36 / 80%);
$black: #363636;
$warning: #f44336;

@mixin flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-driver {
  width: 100%;
  min-height: 100vh;
  background: $orange;
  background-image: url("../../assets/images/bottom-bg.svg"),
    url("../../assets/images/vespa-no-logo.svg"),
    linear-gradient(0deg, $white, $white),
    url("../../assets/images/dash-vertical.svg");
  background-position: bottom left, bottom right 10%, top right,
    top 65% right 4px;
  background-repeat: no-repeat;
  background-size: 50%, 18%, 50% 50%, 10px;

  display: flex;
  flex-direction: column;
}

.card-container {
  @include flex-container;
  flex: 1;
  flex-wrap: wrap;
}

.flex-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 2rem 6rem;
  margin: 0 1rem;
  background: linear-gradient(45deg, rgb(145 108 108), $orange);
  border-radius: 12px;
  box-shadow: 2px 6px 20px 0px #4d3219;
  min-height: 300px;

  h3 {
    color: $white;

    .strong {
      font-size: 1.5em;
      font-weight: bold;
    }
    .op-5 {
      opacity: 0.75;
    }
  }
  form {
    width: 100%;
  }
}

.img {
  margin: 0.5rem 0 1rem 0;

  img {
    width: 35vw;
    height: 35vw;
    min-width: 140px;
    min-height: 140px;
    max-width: 300px;
    max-height: 300px;
    object-fit: fill;
    border-radius: 12px;
  }

  .avatar {
    object-fit: cover;
  }
}

.driver-name,
.personal-details {
  @include flex-container;
  flex-direction: column;

  .driver-name {
    margin-bottom: 0.5rem;

    h4 {
      color: $white;
      font-size: 2em;
      padding: 0;
    }
  }
  span {
    color: $black;
    margin-top: 0.5rem;
  }
}

.not-hired,
.warning-container {
  border-radius: 12px;
  overflow: hidden;
  max-width: 400px;
  width: 100%;
  margin: 0 1rem;
}

.warning-container {
  @include flex-container;
  flex-direction: column;
}
.w-message,
.notify-holder,
.request-msg {
  @include flex-container;
  flex-direction: column;
  width: 100%;
}

.w-message {
  background: $warning;
  padding: 2rem 3rem;
  color: $white;

  h2 {
    font-weight: bold;
    margin: 0;
    margin: 1rem 0 0.3rem 0;
  }
  span {
    text-align: center;
  }
}
.request-msg h5 {
  color: #71efa3;
}

.notify-holder {
  background: $white;
  padding: 1.5rem;

  .btn {
    display: block;
    padding: 10px 44px;
    border: 1px solid #e8760a;
    border-left: 0;
    border-radius: 20px;
    background: $warning;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.not-hired {
  border: 1px solid $black;

  .employee-specs {
    display: flex;
    background: $white;
    padding: 1rem;

    .hire-img img {
      width: 140px;
      height: unset;
    }
    .details {
      padding: 1rem;

      h4 {
        font-size: 1.4em;
        margin-bottom: 2rem;
      }
      div {
        color: $black;
        font-size: 1.1em;
        margin: 10px 0;

        span {
          font-weight: bold;
          opacity: 0.7;
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .flex-form {
    padding: 1rem;
  }
  .w-message {
    padding: 2rem;
  }
  .not-hired {
    .employee-specs {
      .hire-img img {
        min-width: 80px;
        min-height: 80px;
        width: 80px;
      }
    }
  }
}
</style>
