<template>
  <div>
    <div class="return-container">
      <div class="return-inner-container">
        <h2 class="return-title">✅ Επιτυχία Κράτησης Επιστροφής</h2>

        <div>
          <section class="return-state">
            <div style="font-size:24px; margin-bottom: 10px">
              <h3>
                Επιστροφή:
                <strong style="font-weight:700">{{ barcodeId }}</strong>
              </h3>
              <h3 style="margin-top: 10px;">
                Έχει ολοκληρωθεί το αίτημα επιστροφής μέσω θυρίδας Skroutz Point.
              </h3>
              <h3 style="margin-top: 10px;">
                Θα λάβετε εκ νέου μήνυμα στο κινητό σας με οδηγίες όταν η θυρίδα είναι διαθέσιμη.
              </h3>
              <h3 v-if="reason" style="margin-top: 10px;">Λόγος: {{ reason }}</h3>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnOrderSuccess',
  components: {},
  props: {
    barcodeId: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.return-container {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 20px;
  flex: 1;
  display: flex;
}

.return-title {
  font-family: 'Proxima Nova';
  font-size: 2rem;
  font-weight: 500;
  line-height: 100%;
  color: black;
}

.return-header,
.return-state {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 255, 0, 0.6);
  padding: 20px;
  border: 1px solid black;
  border-radius: 10px;
  color: black;
}

@media (max-width: 768px) {
  .return-container {
    padding-top: 0px;
    max-width: 100%;
  }

  .return-header,
  .return-state {
    padding: 10px;
  }
}
</style>
