<template>
  <div>
    <div class="merchantdrop-container">
      <div class="merchantdrop-inner-container">
        <h2 class="merchantdrop-title">✅ Επιτυχία Κράτησης</h2>

        <div>
          <section class="merchantdrop-state">
            <div style="font-size:24px; margin-bottom: 10px">
              <h3>
                Παραγγελία:
                <strong style="font-weight:700">{{ barcodeId }}</strong>
              </h3>
              <h3 style="margin-top: 10px;">
                Πραγματοποιήσατε κράτηση για τοποθέτηση σε Skroutz Point με επιτυχία.
              </h3>
              <h3 style="margin-top: 10px;">
                Όταν η θυρίδα σας είναι έτοιμη, θα λάβετε μήνυμα στο κινητό σας με τις οδηγίες
                παράδοσης.
              </h3>
              <h3 v-if="reason" style="margin-top: 10px;">Λόγος: {{ reason }}</h3>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerchantDropoffSuccess',
  components: {},
  props: {
    barcodeId: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.merchantdrop-container {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 20px;
  flex: 1;
  display: flex;
}

.merchantdrop-title {
  font-family: 'Proxima Nova';
  font-size: 2rem;
  font-weight: 500;
  line-height: 100%;
  color: black;
}

.merchantdrop-header,
.merchantdrop-state {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 255, 0, 0.6);
  padding: 20px;
  border: 1px solid black;
  border-radius: 10px;
  color: black;
}

@media (max-width: 768px) {
  .merchantdrop-container {
    padding-top: 0px;
    max-width: 100%;
  }

  .merchantdrop-header,
  .merchantdrop-state {
    padding: 10px;
  }
}
</style>
