var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-wrapper-app"},[_c('Navbar'),_c('div',{staticClass:"merchantdrop-container-main"},[(_vm.isOrderMerchantDropoffEligible)?_c('div',{staticClass:"container"},[(_vm.isSuccess === true)?_c('div',[_c('MerchantDropoffSuccess',{attrs:{"barcodeId":this.barcodeId,"reason":_vm.other}})],1):(_vm.isSuccess === false)?_c('div',[_c('MerchantDropoffFail',{attrs:{"barcodeId":this.barcodeId,"reason":_vm.errorMessage}})],1):_c('div',[_c('h2',{staticClass:"merchantdrop-title"},[_vm._v("Τοποθέτηση πακέτου σε Locker")]),_c('br'),_c('div',{class:[
            'merchantdrop-main-container',
            { 'merchantdrop-main-container-loading': _vm.isLoading } ]},[_c('div',{class:['loader-overlay', { 'loader-overlay-hide': !_vm.isLoading }]},[_c('Loader')],1),_c('div',{staticClass:"font-size-20"},[_c('div',{staticClass:"merchantdrop-map-header-container"},[_c('div',[_c('h3',[_vm._v(" Αρχική Παραγγελία: "),_c('strong',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.barcodeId))])]),_c('div',{staticClass:"phone-number-container"},[_c('h3',{staticClass:"margin-top-5"},[_vm._v(" Κινητό Τηλέφωνο: ")]),_c('div',{staticClass:"select is-primary is-rounded prefix-select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedPrefix),expression:"selectedPrefix"}],attrs:{"name":"countryPrefix"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedPrefix=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.countryPrefixes),function(ref){
          var code = ref.code;
          var prefix = ref.prefix;
return _c('option',{key:code,domProps:{"value":prefix}},[_vm._v(" "+_vm._s((code + " (" + prefix + ")"))+" ")])}),0)]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatePhone),expression:"updatePhone"}],staticClass:"input is-primary is-rounded phone-input",attrs:{"type":"text","name":"phoneText","placeholder":"New Phone"},domProps:{"value":(_vm.updatePhone)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updatePhoneNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.updatePhone=$event.target.value}}}),_c('button',{on:{"click":_vm.updatePhoneNumber}},[_vm._v("Update 📞")])]),(
                    (_vm.updatePhone !== _vm.phoneNumber || _vm.selectedPrefix !== _vm.prefix) &&
                      _vm.invalidPhoneNumber === false
                  )?_c('div',[_c('p',{staticClass:"warning-text"},[_vm._v("Πρέπει να πατήσετε το κουμπί Update📞!")])]):_vm._e()]),_c('div',{staticClass:"flex-end"},[_c('h4',{staticClass:"information-container"},[_vm._m(0),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Η παραγγελία σας είναι συνδεδεμένη στο νούμερο "+_vm._s(_vm.prefix + _vm.phoneNumber)+". Πρέπει να έχετε πρόσβαση σε αυτή τη συσκευή για να ολοκληρώσετε την τοποθέτηση του. Σε τυχόν αλλαγή του νούμερου πρέπει να πατήσετε το \"Update 📞\". ")])])])]),(_vm.invalidPhoneNumber)?_c('div',{staticClass:"invalid-banner"},[_vm._v(" Το τηλέφωνο που εισάγατε δεν είναι έγκυρο. Παρακαλώ εισάγετε νέο νούμερο για να συνεχίσετε! ")]):_vm._e(),_c('div',{staticClass:"merchantdrop-grid-table"},[_c('h3',[_vm._v(" Επιλέξτε το Skroutz Point από το χάρτη για να τοποθετήσετε το δέμα σας. ")]),_c('button',{staticClass:"show-map-button",on:{"click":function($event){_vm.showMap = true && _vm.isMobile}}},[_vm._v(" Δείξε τα Skroutz Points ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMap || !_vm.isMobile),expression:"showMap || !isMobile"}],staticClass:"popup-place-slm-map-container"},[_c('div',{staticClass:"popup-place-slm-map",staticStyle:{"text-align":"right"}},[(_vm.isMobile)?_c('span',{staticClass:"closeMapBtn",on:{"click":_vm.closeMap}},[_vm._v("ⓧ")]):_vm._e(),_c('div',{staticClass:"merchantdrop-map",attrs:{"id":"skroutzLockerMap"}})])])])]),_c('div',{class:[
              'margin-top-10',
              {
                invisible: !(
                  _vm.merchantDropoffExternalId &&
                  _vm.merchantDropoffLockerFriendlyName &&
                  _vm.merchantDropofLockerAddress &&
                  !_vm.isLoading
                ),
              } ]},[_c('p',{staticClass:"merchantdrop-selected-locker-message"},[_vm._v(" Έχετε επιλέξει το Skroutz Point: "),_c('strong',{staticClass:"merchantdrop-selected-highlight"},[_vm._v(_vm._s(_vm.merchantDropoffLockerFriendlyName))]),_vm._v(" στην διεύθυνση: "),_c('strong',{staticClass:"merchantdrop-selected-highlight"},[_vm._v(_vm._s(_vm.merchantDropofLockerAddress))])]),_c('div',{staticClass:"slot-size-container"},[_c('h3',{staticClass:"font-size-20"},[_vm._v(" Επιλέξτε το μέγεθος του δέματος σας. ")]),_c('br'),_c('button-selector',{ref:"buttonSelector",attrs:{"availableSlotSizes":this.availableSlotSizes},on:{"buttonSelected":_vm.handleButtonSelected}}),_c('br'),(_vm.selectedButton)?_c('div',{staticClass:"font-size-16",staticStyle:{"text-align":"start"}},[_c('p',{staticClass:"margin-top-16 font-size-16"},[_vm._v(" Επιλέξατε Μέγεθος: "),_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedButton.nameGreek))]),_vm._v(". ")]),_vm._v(" Διαστάσεις (εκ.) "),_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedButton.dimensions))])]):_vm._e()],1),_c('div',{staticClass:"bottom-buttons-container"},[_c('div',[_c('button',{class:[
                    'merchantdrop-confirm-button',
                    { 'disable-confirm-button': _vm.disableConfirmBtn || _vm.invalidPhoneNumber } ],attrs:{"disabled":_vm.disableConfirmBtn || _vm.invalidPhoneNumber},on:{"click":_vm.confirmOrder}},[_vm._v(" Επιβεβαίωση ")]),_c('p',{staticClass:"merchantdrop-confirm-button-sub-text"},[_vm._v(" (Θα λάβετε SMS/Viber μήνυμα για το πότε και περαιτέρω οδηγίες) ")])]),(_vm.showCallSupportBtn)?_c('div',[_vm._m(1),_c('p',{staticClass:"merchantdrop-confirm-button-sub-text"},[_vm._v(" (Αν δεν χωράει το δέμα σε κανένα μέγεθος τότε πρέπει να μιλήσετε με το support για να βρεθεί λύση.) ")])]):_vm._e()])])])])]):_c('div',{staticClass:"container"},[_c('div',{class:['loader-overlay', { 'loader-overlay-hide': !_vm.isLoading }]},[_c('Loader')],1),_c('h4',{staticClass:"merchantdrop-title"},[_vm._v(_vm._s(_vm.errorMessage ? _vm.errorMessage : 'Κάτι πήγε στραβά..'))]),_c('br',{staticStyle:{"margin":"80px 0 30px 0"}}),_vm._m(2)])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-icon-margin"},[_c('img',{attrs:{"src":require("../../../assets/images/info-icon.svg"),"alt":"search-icon","width":"24","height":"24"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"tel:+2111982107"}},[_c('button',{staticClass:"call-support-button"},[_vm._v(" Κάλεσε το Support ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-buttons-container"},[_c('div',[_c('a',{staticClass:"call-support-button-wrapper",attrs:{"href":"tel:+2111982107"}},[_c('button',{staticClass:"call-support-button"},[_vm._v(" Κάλεσε το Support ")])]),_c('p',{staticStyle:{"margin-top":"6px","max-width":"400px"}},[_vm._v(" (Σε περίπτωση που έχει συμβεί κάτι λάθος, επικοινώνησε με την υποστήριξη πελατών) ")])])])}]

export { render, staticRenderFns }