<!-- PhoneNumberInput.vue -->
<template>
  <div>
    <input
      type="text"
      v-model="phoneNumberInput"
      @input="validatePhoneNumber"
      @keydown.enter="onEnterPress"
      :class="{
        'phone-input': true,
        'error-border': !isPhoneNumberValid && phoneNumberInput !== '',
        'success-border': isPhoneNumberValid,
      }"
      placeholder="Κινητό της παραγγελίας.."
    />
    <p
      v-if="phoneNumberMessage"
      :class="{ 'error-message': !isPhoneNumberValid, 'success-message': isPhoneNumberValid }"
    >
      {{ phoneNumberMessage }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    clearInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneNumberInput: '',
      phoneNumberMessage: '',
      isPhoneNumberValid: false,
    };
  },
  watch: {
    clearInput(newVal) {
      if (newVal) {
        this.phoneNumberInput = '';
        this.$emit('clear-input-done');
      }
    },
  },
  methods: {
    validatePhoneNumber() {
      const phoneRegex = /^(00|0)?[0-9]{10,12}$/; // phone number with 10 digits and optionally 2 or 4 digits at the front
      if (this.phoneNumberInput === '') {
        this.phoneNumberMessage = '';
        this.isPhoneNumberValid = false;
        this.$emit('update-phone-number', '');
      } else if (phoneRegex.test(this.phoneNumberInput)) {
        this.phoneNumberMessage = '';
        this.isPhoneNumberValid = true;
        this.$emit('update-phone-number', this.phoneNumberInput); // Emit event only when valid
      } else {
        this.phoneNumberMessage = 'Μη έγκυρος αριθμός τηλεφώνου.';
        this.isPhoneNumberValid = false;
        this.$emit('update-phone-number', '');
      }
    },
    onEnterPress() {
      if (this.isPhoneNumberValid) {
        this.$emit('phone-number-enter', this.phoneNumberInput);
      }
    },
  },
};
</script>

<style>
.phone-input {
  border: 3px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
}

.error-border {
  border-color: red;
}

.success-border {
  border-color: green;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}
</style>
